export const COMMUNITIES_ROUTES_KEYS = {
  communities: '/cabinet/communities',
  communitiesFeed: '/cabinet/communities_feed',
  postsModeration: '/cabinet/communities_moderation',
}

export const COMMUNITIES_TABS = [
  {
    key: COMMUNITIES_ROUTES_KEYS.communities,
    label: I18n.t('react.cabinet.community.list_view'),
    link: COMMUNITIES_ROUTES_KEYS.communities,
  },
  {
    key: COMMUNITIES_ROUTES_KEYS.communitiesFeed,
    label: I18n.t('react.cabinet.community.feed_view'),
    link: COMMUNITIES_ROUTES_KEYS.communitiesFeed,
  },
  {
    key: COMMUNITIES_ROUTES_KEYS.postsModeration,
    label: I18n.t('react.cabinet.community.post_review'),
    link: COMMUNITIES_ROUTES_KEYS.postsModeration,
  },
]

export const SORTED_FEED_OPTIONS_KEYS = {
  latest: 'created_at',
  oldest: 'oldest',
  highestLikes: 'likes_count',
  highestComments: 'comments_count',
}

export const SORTED_FEED_OPTIONS = [
  {
    value: SORTED_FEED_OPTIONS_KEYS.latest,
    label: I18n.t('react.shared.community.sorted_options.latest'),
  },
  {
    value: SORTED_FEED_OPTIONS_KEYS.oldest,
    label: I18n.t('react.shared.community.sorted_options.oldest'),
  },
  {
    value: SORTED_FEED_OPTIONS_KEYS.highestLikes,
    label: I18n.t('react.shared.community.sorted_options.highest_likes'),
  },
  {
    value: SORTED_FEED_OPTIONS_KEYS.highestComments,
    label: I18n.t('react.shared.community.sorted_options.highest_comments'),
  },
]

export const FILTER_FEED_OPTIONS_KEYS = {
  allLabels: 'all_labels',
}

export const FILTER_FEED_OPTIONS = [
  {
    value: FILTER_FEED_OPTIONS_KEYS.allLabels,
    label: I18n.t('react.shared.community.filters.all_labels'),
  },
]

export const FILTER_FEED_COURSES_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.shared.community.filters.all_courses'),
  },
]

export const FILTER_FEED_COMMUNITIES_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.shared.community.filters.all_communities'),
  },
]

export const FILTER_FEED_SELLERS_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.shared.community.filters.all_sellers'),
  },
]

export const COMMUNITY_NAME_MAX_LENGTH = 60
export const MAX_COMMENTS_PER_PAGE = 5
export const MAX_POSTS_PER_PAGE = 20
export const MAX_DESCRIPTION_LENGTH = 3000
export const MAX_USERNAME_LENGTH = 50

export const COMMUNITY_MEMBERS_ROLES = {
  owner: 'owner',
  viewer: 'viewer',
}

export const POST_STATUSES = {
  awaitingReview: 'awaiting_review',
  approved: 'approved',
  rejected: 'rejected',
}
