import { makeObservable } from 'mobx'

import * as api from '../api/options.api'
import SharedStore from './shared.store'

export class OptionsStore extends SharedStore<any> {
  storeName = 'OptionsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new OptionsStore()
