const flatten = (array) =>
  array.reduce(
    (accumulator, value) => (Array.isArray(value) ? accumulator.concat(flatten(value)) : accumulator.concat(value)),
    []
  )

const allLessonPages = (lessons) =>
  flatten(
    lessons.map((lesson) => {
      if (!lesson.active) return null

      return lesson.isCategory ? allLessonPages(lesson.children) : lesson
    })
  ).filter(Boolean)

const siblingLessonId = (activeLesson, lessonsList, offset) => {
  const lessonPages = allLessonPages(lessonsList)
  let lessonIndex = lessonPages.findIndex((item) => item.id === activeLesson.id)
  lessonIndex += offset
  const prevLesson = lessonPages[lessonIndex]

  return prevLesson && prevLesson.id
}

export const prevLessonId = (activeLesson, lessonsList) => siblingLessonId(activeLesson, lessonsList, -1)

export const nextLessonId = (activeLesson, lessonsList) => siblingLessonId(activeLesson, lessonsList, 1)
