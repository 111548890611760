import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

import { ACTIVE_PROFILES } from '@elo-kit/constants/profile.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { profile } from 'utils/profileHelper.utils'

const BASE_URL = () => {
  const apiPathCommunity = getElopageConfig('apiPathCommunity')

  return profile.profileType === ACTIVE_PROFILES.payer
    ? `${apiPathCommunity}/v1/viewer/discussions`
    : `${apiPathCommunity}/v1/owner/discussions`
}

export const fetchList = (data) => GET(BASE_URL(), data)

export const fetchItem = (id) => GET(`${BASE_URL()}/${id}`)

export const createItem = (data) => POST(BASE_URL(), data)

export const updateItem = (id, data) => PUT(`${BASE_URL()}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL()}/${id}`)

export const checkExistCommunity = (productId) => GET(`${BASE_URL()}/exists/${productId}`)
