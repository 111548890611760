import { GET } from 'utils/requests.utils'

const BASE_URL = '/payer/sellables'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const checkAccess = (id) => GET(`${BASE_URL}/${id}/check_access`)

/** can't do fetchItem(it break logic for check access), fetchList break list of sellables, only for SellerResolver */
export const fetchMembershipSellable = (id) => GET(BASE_URL, { id })
