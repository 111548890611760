import { action, observable, computed, makeObservable, override } from 'mobx'

import { arrayUniqueByKey } from 'utils/helpers.utils'

import * as api from '../api/communityMembers.api'

import SharedStore from './shared.store'

export class CommunityMembersStore extends SharedStore {
  storeName = 'CommunityMembersStore'
  childApi = api

  @observable loadingMember = {
    mute: [],
  }

  @observable membersAreLoading = false

  @override
  resetList() {
    this.setList([])
    this.updatePagination({ per: 20, page: 1, total: 0 })
  }

  @action setLoading = (key, id, isUpdating) => {
    if (isUpdating) {
      this.loadingMember[key].push(id)
    } else {
      this.loadingMember[key] = this.loadingMember[key].filter((itemId) => itemId !== id)
    }
  }
  @action setMembersAreLoading = (areLoading) => {
    this.membersAreLoading = areLoading
  }

  @action setItemInList = (newData) => {
    const indexItemToUpadate = this.list.findIndex((item) => item.id === newData.id)
    this.list[indexItemToUpadate] = newData
  }

  updateItemInList = async (params) => {
    this.setLoading('mute', params.id, true)
    const resp = await this.childApi.updateItem(params)
    const { data, success } = resp

    if (success) {
      this.setItemInList(data)
      this.setLoading('mute', params.id, false)
    }

    return resp
  }

  fetchMore = async (params) => {
    this.setMembersAreLoading(true)
    const resp = await this.childApi.fetchList({
      ...this.pagination,
      ...params,
      sortKey: this.defaultSortingKey,
      sortDir: this.defaultSortingDirection,
    })

    const { data, success } = resp

    if (success) {
      const { list = [], totalCount = 0 } = data || {}
      this.concatList(list)
      this.updatePagination({ total: totalCount, page: this.pagination.page + 1 })
    }

    this.setMembersAreLoading(false)

    return resp
  }

  @action concatList = (list) => {
    this.list = arrayUniqueByKey([...this.list, ...list])
  }

  @computed get hasMoreMembers() {
    return this.pagination.page - 1 < Math.ceil(this.pagination.total / this.pagination.per)
  }

  constructor() {
    super()

    makeObservable(this)

    this.pagination = {
      per: 20,
      page: 1,
      total: 0,
    }
    this.defaultSortingKey = 'username'
    this.defaultSortingDirection = 'asc'
  }
}

export default new CommunityMembersStore()
