import { action, makeObservable, observable, override } from 'mobx'
import { notify } from 'libs/common/notify'

import {
  FILTER_FEED_OPTIONS_KEYS,
  MAX_COMMENTS_PER_PAGE,
  MAX_POSTS_PER_PAGE,
  SORTED_FEED_OPTIONS_KEYS,
} from 'constants/communities.constants'
import { DEFAULT_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'

import { arrayUniqueByKey, findAndReplace } from 'utils/helpers.utils'

import * as api from '../api/communityPosts.api'
import SharedStore from './shared.store'

export class CommunityPostsStore extends SharedStore {
  storeName = 'CommunityPostsStore'
  childApi = api
  @observable loadMoreButtonLoading = false
  @observable sortKey = SORTED_FEED_OPTIONS_KEYS.latest
  @observable sortDir = 'desc'
  @observable labelKey = ''
  @observable memberFilterKey = null
  @observable postsLoadingById = {}
  @observable creatingPostLoading = false
  @observable commentsLoadingById = {}
  @observable creatingCommentsLoadingById = {}
  @observable commentsPageDataById = {}
  @observable likesLoadingById = {}

  @override
  setList(list) {
    this.list = arrayUniqueByKey(list)
  }

  @override
  resetData() {
    this.resetList()
    this.resetGroupingData()
    this.resetCommentsPageDataById()
    this.resetLoadingData()
    this.resetPagination()
  }

  @override
  handlePaginationChange({ key, value, refetch = true, data }) {
    this.updatePagination({
      [key]: value,
    })

    if (refetch) {
      this.fetchList(data, true)
    }
  }

  @override
  processFetchListResponse(pinnedData, respData) {
    const { list: pinnedList = [], totalCount: pinnedTotalCount = 0 } = pinnedData || {}
    const { list = [], totalCount = 0 } = respData || {}

    this.setList([...pinnedList, ...this.list, ...list])
    this.updatePagination({ total: pinnedTotalCount + totalCount })
  }

  @override
  async fetchList(data, withoutLoading) {
    const { page } = this.queryParams
    if (!withoutLoading) {
      this.toggleLoading(true)
    }
    const pinnedResp = await this.childApi.fetchPinnedList(data)
    const resp = await this.childApi.fetchList({
      page: page,
      per: MAX_POSTS_PER_PAGE,
      sortKey: this.sortKey,
      sortDir: this.sortDir,
      ...(this.labelKey && { discussionLabelId: this.labelKey }),
      ...data,
    })
    if (pinnedResp?.success || resp?.success) {
      this.processFetchListResponse(pinnedResp.data, resp.data)
    }
    this.toggleLoading(false)
    this.setLoadMoreButtonLoading(false)

    return resp
  }

  @override
  async createItem(data, params) {
    this.setCreatingPostLoading(true)
    const resp = await this.childApi.createItem(data, params)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.post_created'))
      if (this.sortKey !== SORTED_FEED_OPTIONS_KEYS.latest || this.sortDir !== 'desc' || this.labelKey) {
        this.fetchList({ communityId: resp.data?.discussionId }, true)
      } else {
        const pinnedPost = this.list.filter((post) => post.pinned)
        this.setList([...pinnedPost, resp.data, ...this.list])
      }
    }
    this.setCreatingPostLoading(false)
    return resp
  }

  @override
  async updateItem(id, data, params) {
    this.setPostsLoadingById({ [id]: true })
    const resp = await this.childApi.updateItem(id, data, params)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.post_updated'))
      this.handleUpdatePost(resp.data)
    }
    this.setPostsLoadingById({ [id]: false })
    return resp
  }

  @override
  async deleteItem(id, data) {
    this.setPostsLoadingById({ [id]: true })
    const resp = await this.childApi.deleteItem(id, data)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.post_removed'))
      this.handleDeletePost(resp.data)
    }
    this.setPostsLoadingById({ [id]: false })
    return resp
  }

  @action setSortDir = (sortDir) => (this.sortDir = sortDir)
  @action setMemberFilterKey = (memberId) => (this.memberFilterKey = memberId)
  @action setSortKey = (sortKey) => (this.sortKey = sortKey)

  @action setLabelKey = (labelKey) => (this.labelKey = labelKey)

  @action setLoadMoreButtonLoading = (loading) => (this.loadMoreButtonLoading = loading)

  @action setCreatingPostLoading = (loading) => (this.creatingPostLoading = loading)

  @action setPostsLoadingById = (postLoadingData) =>
    (this.postsLoadingById = { ...this.postsLoadingById, ...postLoadingData })

  @action setCreatingCommentsLoadingById = (commentLoadingData) =>
    (this.creatingCommentsLoadingById = { ...this.creatingCommentsLoadingById, ...commentLoadingData })

  @action setCommentsLoadingById = (commentLoadingData) =>
    (this.commentsLoadingById = { ...this.commentsLoadingById, ...commentLoadingData })

  @action setCommentsPageDataById = (pageData) =>
    (this.commentsPageDataById = { ...this.commentsPageDataById, ...pageData })

  @action setLikesLoadingById = (likeLoadingData) =>
    (this.likesLoadingById = { ...this.likesLoadingById, ...likeLoadingData })

  @action resetCommentsPageDataById = () => (this.commentsPageDataById = {})

  @action resetGroupingData = () => {
    this.labelKey = ''
    this.sortKey = SORTED_FEED_OPTIONS_KEYS.latest
    this.sortDir = 'desc'
  }

  @action resetLoadingData = () => {
    this.postsLoadingById = {}
    this.commentsLoadingById = {}
    this.creatingCommentsLoadingById = {}
    this.likesLoadingById = {}
  }

  @action resetPagination = () => {
    const pagination = {
      per: MAX_COMMENTS_PER_PAGE,
      page: DEFAULT_PAGE,
      total: 0,
    }
    this.setPagination(pagination)
  }

  findPostById = (postId) => this.list.filter((post) => post.id === postId)[0] || {}

  handleLoadMorePosts = (communityId) => {
    const { page } = this.queryParams

    this.setLoadMoreButtonLoading(true)
    this.handlePaginationChange({
      key: PAGINATION_KEYS.page,
      value: page + 1,
      data: { communityId },
    })
  }

  handleLoadMoreComments = ({ communityId, postId }) => {
    const currentCommentPage = this.commentsPageDataById[postId]?.page || DEFAULT_PAGE
    this.setCommentsPageDataById({
      [postId]: {
        page: currentCommentPage + 1,
        loading: true,
      },
    })
    this.fetchComments({
      communityId,
      postId,
    })
  }

  handleGroupingChange = ({ value, communityId, isSorting, memberId }) => {
    this.toggleLoading(true)
    this.updatePagination({
      [PAGINATION_KEYS.page]: DEFAULT_PAGE,
    })
    this.resetCommentsPageDataById()
    this.resetList()

    if (isSorting) {
      const isOldestSort = value === SORTED_FEED_OPTIONS_KEYS.oldest
      this.setSortKey(isOldestSort ? 'created_at' : value)
      this.setSortDir(isOldestSort ? 'asc' : 'desc')
    } else {
      this.setLabelKey(value === FILTER_FEED_OPTIONS_KEYS.allLabels ? '' : value)
    }

    this.setMemberFilterKey(memberId)
    this.fetchList({
      communityId,
      ...(this.memberFilterKey && { memberId: this.memberFilterKey }),
    })
  }

  handleDeletePost = (deletedPost) => {
    const updatedPostsList = this.list.filter((post) => post.id !== deletedPost.id)
    this.setList(updatedPostsList)
  }

  handleUpdatePost = (updatedPost) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(updatedPost?.id)
    const postWithUpdatedComments = {
      ...updatedPost,
      comments: arrayUniqueByKey([...updatedPost.comments, ...(post.comments || [])]),
    }
    findAndReplace(communityPosts, updatedPost.id, postWithUpdatedComments)
    this.setList(communityPosts)
  }

  handleCreateComment = (newComment) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(newComment.postId)
    const updatedFilteredPost = {
      ...post,
      commentsCount: post.commentsCount + 1,
      comments: [newComment, ...post.comments],
    }
    findAndReplace(communityPosts, newComment.postId, updatedFilteredPost)
    this.setList(communityPosts)
  }

  handleUpdateComment = (updatedComment) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(updatedComment.postId)
    const comments = [...post.comments]
    findAndReplace(comments, updatedComment.id, updatedComment)
    const updatedFilteredPost = {
      ...post,
      comments,
    }
    findAndReplace(communityPosts, updatedComment.postId, updatedFilteredPost)
    this.setList(communityPosts)
  }

  handleDeleteComment = (deletedComment) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(deletedComment.postId)
    const updatedFilteredPost = {
      ...post,
      commentsCount: post.commentsCount - 1,
      comments: post.comments.filter((comment) => comment.id !== deletedComment.id),
    }
    findAndReplace(communityPosts, deletedComment.postId, updatedFilteredPost)
    this.setList(communityPosts)
  }

  handleAddLike = (newLike) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(newLike.postId)
    const updatedFilteredPost = {
      ...post,
      likesCount: post.likesCount + 1,
      liked: true,
    }
    findAndReplace(communityPosts, newLike?.postId, updatedFilteredPost)
    this.setList(communityPosts)
  }

  handleDeleteLike = (deletedLike) => {
    const communityPosts = [...this.list]
    const post = this.findPostById(deletedLike.postId)
    const updatedFilteredPost = {
      ...post,
      likesCount: post.likesCount - 1,
      liked: false,
    }
    findAndReplace(communityPosts, deletedLike?.postId, updatedFilteredPost)
    this.setList(communityPosts)
  }

  processFetchCommentsResponse = ({ data }) => {
    const { list = [] } = data || {}
    const communityPosts = [...this.list]
    const postId = list[0]?.postId

    if (postId) {
      const post = this.findPostById(postId)
      const updatedComments = arrayUniqueByKey([...(post?.comments || []), ...list])
      const updatedFilteredPost = {
        ...post,
        comments: updatedComments,
      }
      findAndReplace(communityPosts, postId, updatedFilteredPost)
      this.setList(communityPosts)
    }
  }

  createComment = async (data, params) => {
    this.setCreatingCommentsLoadingById({ [data.postId]: true })
    const resp = await this.childApi.createComment(data, params)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.comment_created'))
      this.handleCreateComment(resp.data)
    }
    this.setCreatingCommentsLoadingById({ [data.postId]: false })
    return resp
  }

  updateComment = async (commentId, data, params) => {
    this.setCommentsLoadingById({ [commentId]: true })
    const resp = await this.childApi.updateComment(commentId, data, params)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.comment_updated'))
      this.handleUpdateComment(resp.data)
    }
    this.setCommentsLoadingById({ [commentId]: false })
    return resp
  }

  deleteComment = async (commentId, data) => {
    this.setCommentsLoadingById({ [commentId]: true })
    const resp = await this.childApi.deleteComment(commentId, data)
    if (resp?.success) {
      notify('success', I18n.t('react.shared.notific.comment_removed'))
      this.handleDeleteComment(resp.data)
    }
    this.setCommentsLoadingById({ [commentId]: false })
    return resp
  }

  fetchComments = async (data) => {
    const { postId } = data
    const resp = await this.childApi.fetchComments({
      page: this.commentsPageDataById[postId]?.page || DEFAULT_PAGE,
      per: MAX_COMMENTS_PER_PAGE,
      ...data,
    })
    if (resp?.success) {
      this.processFetchCommentsResponse(resp)
    }
    this.setCommentsPageDataById({
      [postId]: {
        ...this.commentsPageDataById[postId],
        loading: false,
      },
    })
    return resp
  }

  createLike = async (data, params) => {
    this.setLikesLoadingById({ [data.postId]: true })
    const resp = await this.childApi.createLike(data, params)
    if (resp?.success) {
      this.handleAddLike(resp.data)
    }
    this.setLikesLoadingById({ [data.postId]: false })
    return resp
  }

  deleteLike = async (data) => {
    this.setLikesLoadingById({ [data.postId]: true })
    const resp = await this.childApi.deleteLike(data)
    if (resp?.success) {
      this.handleDeleteLike(resp.data)
    }
    this.setLikesLoadingById({ [data.postId]: false })
    return resp
  }

  clean = () => {
    this.setList([])
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new CommunityPostsStore()
