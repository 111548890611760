import { POST, GET, PUT, DELETE } from 'utils/requests.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

const apiPathCommunity = getElopageConfig('apiPathCommunity')

const BASE_URL = `${apiPathCommunity}/v1/discussions`

export const createItem = (data, params) => POST(`${BASE_URL}/${data.communityId}/posts`, data, params)

export const updateItem = (postId, data, params) => PUT(`${BASE_URL}/${data.communityId}/posts/${postId}`, data, params)

export const deleteItem = (postId, data) => DELETE(`${BASE_URL}/${data.communityId}/posts/${postId}`)

export const fetchList = (data) => GET(`${BASE_URL}/${data.communityId}/posts`, data)

export const fetchPinnedList = (data) => GET(`${BASE_URL}/${data.communityId}/posts/pinned`, data)

export const fetchComments = (data) => GET(`${BASE_URL}/${data.communityId}/posts/${data.postId}/comments`, data)

export const createComment = (data, params) =>
  POST(`${BASE_URL}/${data.communityId}/posts/${data.postId}/comments`, data, params)

export const updateComment = (commentId, data, params) =>
  PUT(`${BASE_URL}/${data.communityId}/posts/${data.postId}/comments/${commentId}`, data, params)

export const deleteComment = (commentId, data) =>
  DELETE(`${BASE_URL}/${data.communityId}/posts/${data.postId}/comments/${commentId}`, data)

export const createLike = (data, params) =>
  POST(`${BASE_URL}/${data.communityId}/posts/${data.postId}/votes/like`, data, params)

export const deleteLike = (data) => DELETE(`${BASE_URL}/${data.communityId}/posts/${data.postId}/votes/like`, data)
