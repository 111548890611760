import { observable, action, makeObservable } from 'mobx'

import SharedStore from './shared.store'

class NotificationsStore extends SharedStore<any> {
  storeName = 'NotificationsStore'
  @observable notificationCounts = new Map(
    Object.entries({
      elopageNotificationCount: 0,
      totalCount: 0,
    })
  )

  @action setCount = (data) => {
    this.notificationCounts.set('elopageNotificationCount', data.elopageNotificationCount)
    this.notificationCounts.set('totalCount', data.totalCount)
  }

  fetchUnreadCount = async () => {
    this.toggleLoading(true)

    const { data = {} } = await this.childApi.fetchUnreadCount({ platform: 'web', skipErrorNotific: true })

    this.setCount(data)

    this.toggleLoading(false)
  }

  setNotificState = (method) => async (id, length) => {
    if (!this.customLoadingEnabled) this.toggleCustomLoading(null)
    this.toggleLoading(true)

    const resp = await this.childApi[method](id)

    if (resp.success) {
      await this.fetchUnreadCount()
      await this.fetchList()
    }

    if (length === 1) this.toggleCustomLoading(null)
    this.toggleLoading(false)
  }

  read = this.setNotificState('read')
  unread = this.setNotificState('unread')

  readAll = async () => {
    this.toggleLoading(true)

    const resp = await this.childApi.readAll()

    if (resp.success) {
      await this.fetchUnreadCount()
      await this.fetchList()
    }

    this.toggleLoading(false)
  }

  constructor() {
    super()
    makeObservable(this)
  }
}

export default NotificationsStore
