import { GET, PUT, POST } from 'utils/requests.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

const apiPathCommunity = getElopageConfig('apiPathCommunity')

const BASE_URL = `${apiPathCommunity}/v1/discussions`

export const fetchList = (data) => GET(`${BASE_URL}/${data.communityId}/members`, data)

export const fetchItem = (data) => GET(`${BASE_URL}/${data.communityId}/members/${data.userId}`)

export const updateItem = (data) => PUT(`${BASE_URL}/${data.communityId}/members/${data.id}`, data)

export const createItem = (data) => POST(`${BASE_URL}/${data.communityId}/members`, data)
