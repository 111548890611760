import { action, observable, makeObservable, override } from 'mobx'

import { DEFAULT_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'

import * as api from '../api/communities.api'
import productsStore from '../../cabinet/stores/products.store'
import SharedStore from './shared.store'

export class CommunitiesStore extends SharedStore {
  storeName = 'CommunitiesStore'
  childApi = api
  @observable products = []

  @override
  setItem({ data }) {
    this.item = data
  }

  @override
  processFetchListResponse({ data }) {
    const { list = [], totalCount = 0 } = data || {}
    this.setList(list)
    this.updatePagination({ total: totalCount })

    //handle out of range page query param
    const pageCount = this.pagination.total ? Math.ceil(this.pagination.total / this.pagination.per) : DEFAULT_PAGE

    if (this.pagination.page > pageCount) {
      this.handlePaginationChange(PAGINATION_KEYS.page, pageCount)
    }
    if (this.pagination.page < DEFAULT_PAGE) {
      this.handlePaginationChange(PAGINATION_KEYS.page, DEFAULT_PAGE)
    }
  }

  @override
  async fetchList(data) {
    const { shouldFetchProducts = true } = data || {}
    this.toggleLoading(true)
    const resp = await this.childApi.fetchList({
      ...this.queryParams,
      ...data,
    })
    this.processFetchListResponse(resp)

    if (shouldFetchProducts) {
      await this.fetchProduct(resp)
    } else {
      this.toggleLoading(false)
    }

    return resp
  }

  @action setProducts = (products) => {
    this.products = products
  }

  @action resetProducts = () => {
    this.products = []
  }

  fetchProduct = async ({ data }) => {
    const productIdsArray = data?.list?.map(({ productId }) => productId)
    const uniqueProductIdsArray = [...new Set(productIdsArray)]
    const productResp = await productsStore.fetchList({ id: uniqueProductIdsArray })

    if (productResp?.success) {
      this.setProducts(productResp.data?.list)
    }

    this.toggleLoading(false)
  }

  clean = () => {
    this.setList([])
    this.setItem({})
  }

  constructor() {
    super()

    makeObservable(this)
  }
  checkExistCommunity = async (productId) => await this.childApi.checkExistCommunity(productId)
}

export default new CommunitiesStore()
