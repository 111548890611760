import * as api from '../api/communityMember.api'
import { checkExistMemberName } from '../api/communitiesValidation.api'
import SharedStore from './shared.store'

export class CommunityMemberStore extends SharedStore<any> {
  storeName = 'CommunityMemberStore'
  childApi = api

  validateMemberName = checkExistMemberName
}

export default new CommunityMemberStore()
