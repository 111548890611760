import { GET } from 'utils/requests.utils'

import { ACTIVE_PROFILES } from '@elo-kit/constants/profile.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { profile } from 'utils/profileHelper.utils'

const BASE_URL = () => {
  const apiPathCommunity = getElopageConfig('apiPathCommunity')

  return profile.profileType === ACTIVE_PROFILES.payer
    ? `${apiPathCommunity}/v1/viewer/posts`
    : `${apiPathCommunity}/v1/owner/posts`
}

export const fetchList = (data) => GET(BASE_URL(), data)
