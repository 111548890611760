import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellables.api'

export class SellablesStore extends SharedStore {
  storeName = 'SellablesStore'
  childApi = api

  // set item for course/membership access
  @action setMembershipSellable = (item) => {
    this.item = item
  }

  // fetching sellable without initialize session
  fetchMembershipSellable = async (id) => {
    this.toggleLoading(true)

    const resp = await api.fetchMembershipSellable(id)
    const { success, data } = resp || {}
    const { list = [] } = data || {}

    if (success) {
      this.setMembershipSellable(list[0] || {})
    }

    this.toggleLoading(false)
  }

  constructor() {
    super()

    makeObservable(this)

    this.pagination = {
      per: 12,
      page: 1,
      total: 0,
    }
  }
}

export default new SellablesStore()
