import { action, observable, makeObservable, override } from 'mobx'

import { MAX_POSTS_PER_PAGE } from 'constants/communities.constants'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'

import { arrayUniqueByKey } from 'utils/helpers.utils'
import { profile } from 'utils/profileHelper.utils'

import * as api from '../api/communitiesFeedPosts.api.js'
import productsStore from '../../cabinet/stores/products.store'
import sellablesStore from '../../payer/stores/sellables.store'
import SharedStore from './shared.store'

export class CommunitiesFeedPostsStore extends SharedStore {
  storeName = 'CommunitiesFeedPostsStore'
  childApi = api
  @observable loadMoreButtonLoading = false
  @observable productId = ''
  @observable discussionId = ''
  @observable sellerId = ''
  @observable products = []

  @override
  setList(list) {
    this.list = arrayUniqueByKey(list)
  }

  @override
  resetData() {
    this.resetList()
    this.resetProducts()
    this.productId = ''
    this.discussionId = ''
    this.sellerId = ''
    const pagination = {
      per: DEFAULT_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
      total: 0,
    }
    this.setPagination(pagination)
  }

  fetchProduct = async (list) => {
    const isPayerCabinet = profile.profileType === ELOPAGE_CABINETS.payer
    const productIdsArray = list?.map(({ discussion = {} }) => discussion.productId)
    const uniqueProductIdsArray = [...new Set(productIdsArray)]
    let store = productsStore
    let reqData = Object.assign({ id: uniqueProductIdsArray })

    if (isPayerCabinet) {
      store = sellablesStore
      reqData = Object.assign({ productId: uniqueProductIdsArray })
    }

    const productResp = await store.fetchList(reqData)

    if (productResp?.success) {
      this.setProducts([...this.products, ...productResp.data?.list])
    }

    this.toggleLoading(false)
  }

  @override
  handleFilterChange({ productId, discussionId, sellerId }) {
    this.updatePagination({
      [PAGINATION_KEYS.page]: DEFAULT_PAGE,
    })
    this.resetList()

    if (productId || productId === '') {
      this.setProductId(productId)
    }
    if (discussionId || discussionId === '') {
      this.setDiscussionId(discussionId)
    }
    if (sellerId || sellerId === '') {
      this.setSellerId(sellerId)
    }

    this.fetchList()
  }

  @override
  processFetchListResponse(resp) {
    const { list = [], totalCount = 0 } = resp || {}

    this.fetchProduct(list)

    this.setList([...this.list, ...list])
    this.updatePagination({ total: totalCount })
  }

  @override
  async fetchList(data, withoutLoading) {
    const { page } = this.queryParams

    if (!withoutLoading) {
      this.toggleLoading(true)
    }
    const resp = await api.fetchList({
      page: page,
      per: MAX_POSTS_PER_PAGE,
      ...(this.productId && { productId: this.productId }),
      ...(this.discussionId && { discussionId: this.discussionId }),
      ...(this.sellerId && { memberUserId: this.sellerId }),
      ...data,
    })

    if (resp?.success) {
      this.processFetchListResponse(resp.data)
    } else {
      this.toggleLoading(false)
    }

    this.setLoadMoreButtonLoading(false)

    return resp
  }

  @action setProductId = (productId) => (this.productId = productId)
  @action setDiscussionId = (discussionId) => (this.discussionId = discussionId)
  @action setSellerId = (sellerId) => (this.sellerId = sellerId)
  @action setLoadMoreButtonLoading = (loading) => (this.loadMoreButtonLoading = loading)

  @action setProducts = (products) => {
    this.products = products
  }

  @action resetProducts = () => {
    this.products = []
  }

  handleLoadMorePosts = () => {
    const { page } = this.queryParams

    this.setLoadMoreButtonLoading(true)
    this.updatePagination({
      [PAGINATION_KEYS.page]: page + 1,
    })
    this.fetchList({}, true)
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new CommunitiesFeedPostsStore()
