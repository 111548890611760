import { GET } from 'utils/requests.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { HTTPResponse } from 'types/responses'

const apiPathCommunity = getElopageConfig('apiPathCommunity')
const BASE_URL = `${apiPathCommunity}/v1/validations`

interface URLParams {
  name: string
  discussionId: number
}

export const checkExistMemberName = (data: URLParams, params: Record<string, any> = {}) =>
  GET<HTTPResponse>(`${BASE_URL}/check_username`, {
    ...data,
    ...params,
  })
